.layout {
  height: 100vh;
  background-color: #f5f5f5 !important;

  .header {
    height: 80px;
    padding: 0 128px;
    color: #fff;
    background: url('../../../../static//image/headbg.png')no-repeat center center;
    background-size: 100% 100%;

    &_wrap {
      display: flex;
      align-items: center;
      height: 100%;

      .logo {
        margin-right: 16px;
        height: 40px;
        width: 158px;

        img {
          width: 100%;
          height: 100%;
        }

        // height: 50px;
        // width: 50px;
        // background-color: aqua;
        // border-radius: 50%;
      }

      .word {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .content {
    flex: auto;
    padding: 0 128px;

    .site-layout-content {
      background: #fff;
      height: calc(100vh - 220px);
    }
  }

  .footer {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    text-align: center;
    background-color: #f5f5f5 !important;
  }
}