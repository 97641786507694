.crumb {
  margin: 16px 0;

  .first {
    // padding-left: 8px;
    color: #000000;
    font-size: 16px;
  }
  .first_light {
    // padding-left: 8px;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
    // vertical-align: text-top;
  }

  .other_light {
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
    // vertical-align: text-top;
  }
  .icon_row {
    // display: flex;
    // align-items: center;
    :global {
      .next-icon.next-large:before {
        font-size: 20px;
      }
    }
    .icon {
      color: #17a085;
    }
  }

  .icon_back {
    color: #17a085;
  }
}
