body {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  background-color: #f5f5f5 !important;
}

@font-face {
  font-family: NextIcon;
  src: url('//at.alicdn.com/t/font_2463158_wnj6k0e3rwn.eot');
  src: url('//at.alicdn.com/t/font_2463158_wnj6k0e3rwn.eot?#iefix')
      format('embedded-opentype'),
    url('//at.alicdn.com/t/font_2463158_wnj6k0e3rwn.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_2463158_wnj6k0e3rwn.woff') format('woff'),
    url('//at.alicdn.com/t/font_2463158_wnj6k0e3rwn.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_2463158_wnj6k0e3rwn.svg#NextIcon') format('svg');
}
